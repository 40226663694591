import DefaultLayout from "./layouts/DefaultLayout";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { BrowserRouter as Router } from "react-router-dom";
import { useRoutes } from "./routes";
import { PageContext } from "./context/PageContext";
import { useState } from "react";
import { useAuth } from "./hooks/auth.hook";
import { AuthContext } from "./context/AuthContext";
import UnauthorizedLayout from "./layouts/UnauthorizedLayout";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { useEventBus } from "./hooks/eventBus.hook";

export default function App() {

  const auth = useAuth()
  const isAuthenticated = !!auth.token

  const routes = useRoutes(isAuthenticated)
  const [title, setTitle] = useState("");
  const eventBus = useEventBus()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (!auth.ready) {
    return <CircularProgress />
  }

  return (
    <AuthContext.Provider value={ {...auth} }>
      <PageContext.Provider value={{ title, setTitle, eventBus, isMobile }}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Router>
            { isAuthenticated && <DefaultLayout>
              {routes}
            </DefaultLayout> }
            { !isAuthenticated && <UnauthorizedLayout>
              {routes}
            </UnauthorizedLayout> }
          </Router>
        </ThemeProvider>
      </PageContext.Provider>
    </AuthContext.Provider>
  );
}