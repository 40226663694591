import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import AppBar from "../components/AppBar";
import { Paper, Toolbar } from "@mui/material";
import SideBar from "../components/SideBar";
import Copyright from "../components/Copyright";
import { PageContext } from "../context/PageContext";


export default function DefaultLayout({ children }) {
  
  const { isMobile } = React.useContext(PageContext)
  const drawerWidth = 240;

  const [open, setOpen] = React.useState(!isMobile);
  const toggleDrawer = () => {
    setOpen((o) => !o);
  };

  return (
    <Box sx={{ display: "flex" }} role="presentation">
      <AppBar
        open={open}
        isMobile={isMobile}
        drawerWidth={drawerWidth}
        toggleDrawer={toggleDrawer}
      />

      <SideBar
        open={open}
        isMobile={isMobile}
        drawerWidth={drawerWidth}
        toggleDrawer={toggleDrawer}
      />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container
          sx={{
            mt: { xs: 2, sm: 3, md: 4 },
            mb: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Paper sx={{ p: { xs: '20px', md: "40px" } }}>
            {children}
          </Paper>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}
