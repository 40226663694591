import { Alert, Button, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import DeleteLinkDialog from "./DeleteLinkDialog";
import { copyTextToClipboard } from "../helpers";

export const LinksList = ({ links, onRemove, showNumColumn = true }) => {

    const [alertText, setAlertText] = useState(null)
    const [alertSeverity, setAlertSeverity] = useState("success")

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setAlertText(null)
    }

    const copyLink = async (url) => {
      const ok = await copyTextToClipboard(url)
      if (ok) {
        setAlertSeverity("success")
        setAlertText(`Ссылка ${url} успешно скопирована`)
      }
    }

    if (!links || !links.length)
        return <p className="center">Ссылок пока нет</p>

    return (
      <React.Fragment>
        <Table size="small">
          <TableHead>
            <TableRow>
              {showNumColumn && <TableCell>№</TableCell>}
              <TableCell>Ссылка</TableCell>
              <TableCell>Переходов</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Детали</TableCell>
              <TableCell>Удалить</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {links.map((row, index) => (
              <TableRow key={row._id}>
                {showNumColumn && <TableCell>{index + 1}</TableCell>}
                <TableCell>
                  <b>
                    <a href={row.shortUrl} rel="noreferrer" target="_blank">
                      {row.shortUrl}
                    </a>
                  </b>{" "}
                  <Button onClick={() => copyLink(row.shortUrl)}>
                    Копировать
                  </Button>
                  <br />
                  <Typography variant="span" color="text.secondary">
                    {row.target}
                  </Typography>
                </TableCell>
                <TableCell align="center">{row.clicks}</TableCell>
                <TableCell>{new Date(row.date).toLocaleString()}</TableCell>
                <TableCell>
                  <Link to={`/detail/${row._id}`}>Подробнее</Link>
                </TableCell>
                <TableCell>
                  <DeleteLinkDialog link={row} onRemove={onRemove} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Snackbar
          open={!!alertText}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
}