import { styled } from '@mui/material/styles'
import MuiAppBar from "@mui/material/AppBar";
import { IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";
import { useContext } from 'react';
import { PageContext } from '../context/PageContext';

const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth" && prop !== "isMobile",
})(({ theme, open, drawerWidth, isMobile }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && !isMobile && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppBar({ open, drawerWidth, toggleDrawer, isMobile }) {

    const page = useContext(PageContext);

    return (
      <AppBarStyled position="absolute" open={open} drawerWidth={drawerWidth} isMobile={isMobile}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
            ...(drawerWidth === 0 && { ml: '30px' })
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(((open || drawerWidth === 0)) && { display: "none" })
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {page.title}
          </Typography>
          {/* <IconButton color="inherit" sx={ { ...( drawerWidth === 0 && { display: 'none' }) } }>
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBarStyled>
    );
    
}