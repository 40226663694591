import { Alert, Box } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHttp } from "../hooks/http.hook";
import { LinksList } from "./LinksList";
import { PageContext } from "../context/PageContext";
import { Loader } from "./Loader";

export const LinksControl = ({ 
  maxNumber = 0, 
  reverseOrder = false, 
  showNumColumn = true
}) => {

  const [links, setLinks] = useState([])
  const { loading, request, error, cancelRequests, resumeRequests } = useHttp()
  const { eventBus } = useContext(PageContext)

  const onLinkCreated = (linksArray) => (data) => {
    // if (!linksArray || linksArray.length === 0) {
    //   console.log("some shitty links array... return from here");
    //   return;
    // }
    const existing = linksArray.find((l) => l._id === data._id);
    if (existing) {
      //console.log("Link already exists", existing);
    } else {
      let newLinks = [...linksArray];
      if (reverseOrder) {
        newLinks.reverse();
        newLinks.push(data);
        newLinks.reverse();
        if (maxNumber > 0) newLinks.splice(newLinks.length - 1, 1);
      } else {
        if (maxNumber > 0) newLinks.splice(0, 1);
        newLinks.push(data);
      }
      //console.log("setting new links", newLinks);
      setLinks(newLinks);
    }
  }

  const fetchLinks = useCallback(async () => {
    try {
      eventBus.off("ShortLinkAdded", onLinkCreated(links));
      let fetched = await request({ 
        url: "/api/link", 
        authorized: true,
        retryAfterTokenRefresh: true
      });
      if (reverseOrder)
        fetched.reverse()
      if (maxNumber > 0)
        fetched = fetched.slice(0, 5)
      setLinks(fetched);
      eventBus.on("ShortLinkAdded", onLinkCreated(fetched));
    } catch (e) {}
  }, [request, maxNumber, reverseOrder, eventBus]);


  const removeLink = async (link) => {
    if (maxNumber > 0) {
      fetchLinks();
      return;
    }

    const links2 = [...links];
    const idx = links2.indexOf(link);
    if (idx > -1) links2.splice(idx, 1);
    setLinks(links2);
  };

  useEffect(() => {
    resumeRequests();
    fetchLinks();
    return () => cancelRequests()
  }, [fetchLinks, cancelRequests, resumeRequests]);

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {!loading && error && (
        <Box>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      )}
      {!loading && !error && links && (
        <LinksList
          links={links}
          onRemove={removeLink}
          showNumColumn={showNumColumn}
        />
      )}
    </>
  );
}