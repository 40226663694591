import React, { useCallback, useContext, useEffect, useState } from "react";
import { PageContext } from "../context/PageContext";
import { Alert, AlertTitle, Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material";
import Title from "../components/Title";
import { NavLink } from "react-router-dom";
import { useHttp } from "../hooks/http.hook";
import { LinksControl } from "../components/LinksControl";
import { LinkCard } from "../components/LinkCard";

export const CreatePage = () => {

  const page = useContext(PageContext)
  const [link, setLink] = useState("")
  const [domain, setDomain] = useState("yglk.ru")
  const [customCode, setCustomCode] = useState("")
  const [linkExists, setLinkExists] = useState(false)
  const [newLinkData, setNewLinkData] = useState(null)
  const { loading, request, error, clearError, cancelRequests, resumeRequests } = useHttp()

  const createShortLink = useCallback(async () => {
    try {
      setNewLinkData(null)
      setLinkExists(false)
      clearError()
      const data = await request({
        url: "/api/link/generate",
        method: "POST",
        body: { 
          target: link,
          code: customCode,
          domain
        },
        authorized: true,
        retryAfterTokenRefresh: true,
      });
      setNewLinkData(data);
      setLink("");
      setCustomCode("");
      if (data.isNew)
        page.eventBus.emit('ShortLinkAdded', data);
      else
        setLinkExists(true)
      //navigate(`/detail/${data._id}`);
    } catch (e) {

    }
  }, [request, link, clearError, page.eventBus, customCode, domain])

  const handleSubmit = async (event) => {
    event.preventDefault()
    await createShortLink()
  }

  useEffect(() => {
    resumeRequests();
    page.setTitle("Создать короткую ссылку");
    return () => cancelRequests();
  }, [page, cancelRequests, resumeRequests])

  return (
    <>
      <Box component="form" spacing={3} onSubmit={handleSubmit}>
        <Grid container spacing={{ xs: 3, sm: 3 }}>
          <Grid item xs={12}>
            <TextField
              label="Вставьте длинную ссылку"
              name="link"
              value={link ?? ""}
              onChange={(e) => setLink(e.target.value)}
              placeholder="Например, https://your-super-long-link.ru/very-long-item-url-which-you-want-shorten"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <FormControl
              sx={{ m: 0, width: "100%" }}
              className="sl-domain-selector"
            >
              <InputLabel>Домен</InputLabel>
              <Select
                value={domain}
                label="Домен"
                defaultValue="yglk.ru"
                onChange={(ev) => setDomain(ev.target.value)}
              >
                <MenuItem value="yglk.ru">yglk.ru</MenuItem>
                <MenuItem value="sl.harmankaur.ru">sl.harmankaur.ru</MenuItem>
                <MenuItem value="s.yoga8weeks.ru">s.yoga8weeks.ru</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {!page.isMobile && (
            <Grid item sm={1}>
              <Divider
                sx={{
                  fontSize: 22,
                  paddingTop: "12px",
                }}
              >
                /
              </Divider>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={8}>
            <TextField
              label="Короткая часть (не обязательно)"
              name="code"
              value={customCode}
              onChange={(e) => setCustomCode(e.target.value)}
              placeholder="Например: special-offer"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <Button
              variant="contained"
              type="submit"
              sx={{ width: "100%", p: "12px 30px" }}
              onClick={createShortLink}
              disabled={loading}
            >
              Сократить
            </Button>
          </Grid>
          {linkExists && (
            <Grid item xs={12}>
              <Alert severity="info">
                Вы уже сокращали данную ссылку{" "}
                {new Date(newLinkData.date).toLocaleString()}
              </Alert>
            </Grid>
          )}
          {newLinkData && (
            <Grid item xs={12}>
              <LinkCard link={newLinkData} />
            </Grid>
          )}
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>Произошла ошибка</AlertTitle>
                {error.errors ? error.errors[0]?.msg : error.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </Box>
      <Paper sx={{ p: "20px 40px", mt: "30px" }}>
        <Grid
          container
          columnSpacing={2}
          sx={{
            overflowY: "auto",
            overflowX: "auto",
          }}
          flexDirection={"column"}
        >
          <React.Fragment>
            <Title>Последние ссылки</Title>
            <LinksControl
              maxNumber={5}
              reverseOrder={true}
              showNumColumn={false}
            />
            <NavLink color="primary" to="/links" style={{ marginTop: 13 }}>
              Все ссылки
            </NavLink>
          </React.Fragment>
        </Grid>
      </Paper>
    </>
  );
}
