import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

export const SessionList = ({ sessions, onRemove, showNumColumn = true }) => {

  const columns = [
    { field: 'issued', headerName: 'Дата входа', width: 200 },
    { field: 'remoteAddr', headerName: 'IP-адрес', width: 200 },
    { field: 'userAgent', headerName: 'Устройство', width: 500 }
  ]

  return (
    <DataGrid 
      rows={sessions}
      columns={columns}
    />
  )

    // return (
    //   <React.Fragment>
    //     <Table size="small">
    //       <TableHead>
    //         <TableRow>
    //           {showNumColumn && <TableCell>№</TableCell>}
    //           <TableCell>Дата входа</TableCell>
    //           <TableCell>IP-адрес</TableCell>
    //           <TableCell>Устройство</TableCell>
    //           {/* <TableCell>Завершить</TableCell> */}
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {sessions.map((row, index) => (
    //           <TableRow key={row.id}>
    //             {showNumColumn && <TableCell>{index + 1}</TableCell>}
    //             <TableCell>{new Date(row.issued).toLocaleString()}</TableCell>
    //             <TableCell>
    //               {row.remoteAddr}
    //             </TableCell>
    //             <TableCell>
    //               {row.userAgent}
    //             </TableCell>
    //             <TableCell>
    //               {/* <DropSessionDialog session={row} onRemove={onRemove} /> */}
    //             </TableCell>
    //           </TableRow>
    //         ))}
    //       </TableBody>
    //     </Table>
    //   </React.Fragment>
    // );
}