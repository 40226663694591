import { useState, useCallback, useEffect } from 'react'
import { parseJwt } from '../helpers'

const storageName = 'userData'

export const useAuth = () => {
    const [token, setToken] = useState(null)
    const [refreshToken, setRefreshToken] = useState(null)
    const [ready, setReady] = useState(false)
    const [userId, setUserId] = useState(null)
    const [role, setRole] = useState(null)

    const login = useCallback((jwtToken, uid, refreshToken) => {
        setToken(jwtToken)
        setUserId(uid)
        setRefreshToken(refreshToken)
        const payload = parseJwt(jwtToken)
        //console.log(payload)
        setRole(payload.role)

        localStorage.setItem(storageName, JSON.stringify({ userId: uid, token: jwtToken, refreshToken }));
    }, [])

    const logout = useCallback(() => {
        setToken(null)
        setUserId(null)
        setRefreshToken(null)
        setRole(null)

        localStorage.removeItem(storageName)
    }, [])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName))
        if (data && data.token)
            login(data.token, data.userId, data.refreshToken, parseJwt(data.token).role)
        setReady(true)
    }, [login])

    return { login, logout, token, refreshToken, userId, ready, role }
}