import { Grid, Paper } from "@mui/material";
import Chart from "../demo/Chart";
import Deposits from "../demo/Deposits";
import Orders from "../demo/Orders";
import { useContext, useEffect } from "react";
import { PageContext } from "../context/PageContext";

export const DashboardPage = () => {

    const page = useContext(PageContext)
    useEffect(() => {
      page.setTitle("Dashboard");
    }, [page]);

    return (
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <Chart />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <Deposits />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Orders />
          </Paper>
        </Grid>
      </Grid>
    );
}