import { useState } from "react"

export const useEventBus = () => {
    const [events, setEvents] = useState({})

    function on (ev, fn) {
        //console.log('new listener added: ', ev, fn)
        if (typeof fn !== "function")
        {
            console.error("useEventBus.on callback is not a function!")
            return
        }
        if (events[ev])
            events[ev].push(fn)
        else
            events[ev] = [fn]
    }

    function off (ev, fn) {
        //console.log('listener removed', ev, fn)
        if (!arguments.length) {
            setEvents({})
            return
        }
        if (Array.isArray(ev)) {
            for (let i = 0, len = ev.length; i < len; i++)
                off(ev[i], fn)
            return
        }
        if (events[ev]) {
            const idx = events[ev].indexOf(fn)
            if (idx !== -1) events[ev].splice(idx, 1)
        }
    }

    function emit(ev, ...args) {
        const cbs = events[ev]
        if (cbs) {
            //console.log('emit event', ev, [...args], [...cbs])
            cbs.forEach(fn => fn(...args))
        }
    }

    return { on, off, emit }
}