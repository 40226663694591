import { useCallback, useContext, useState } from "react";
//import { useNavigate } from 'react-router-dom'
import { AuthContext } from "../context/AuthContext";
import { useAuth } from "./auth.hook";
import { delay } from "../helpers";

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [numTry, setNumTry] = useState(0);
  //const [objectId] = useState(Math.floor(Math.random() * 100000))
  const [abort, setAbort] = useState(false)
  const { login, logout, token, refreshToken } = useContext(AuthContext);
  const { ready } = useAuth();
  //const navigate = useNavigate();
  let request;

  const clearError = useCallback(() => setError(null), []);

  const cancelRequests = useCallback(() => 
  {
    //console.log(`http hook ${objectId} requests cancelled`)
    setAbort(true)
  }, [])

  const resumeRequests = useCallback(() => {
    //console.log(`http hook ${objectId} requests resumed`)
    setAbort(false)
  }, [])

  const doRefreshToken = useCallback(async () => {
    if (abort) return;
    try {
      //console.log('Refresh Token', refreshToken)
      const resp = await request({
        url: "/api/auth/refresh-token",
        method: "POST",
        body: { token: refreshToken },
        maxRetries: 5,
        forceSendReq: true
      });
      //console.log(resp)
      return resp
    } catch (e) {
      //console.log(e.message)
    }
  }, [request, refreshToken, abort]);

  request = useCallback(
    async ({
      url,
      method = "GET",
      body = null,
      authorized = false,
      maxRetries = 10,
      retryDelay = 1000,
      retryCount = 0,
      retryAfterTokenRefresh = false,
      tokenOverride = null,
      forceSendReq = false,
    }) => {
      if (!ready && !forceSendReq) return;
      if (abort) return;
      setLoading(true);
      let response = null;
      let headers = {};
      let data = {};
      let serializedBody = null;
      try {
        setNumTry(retryCount);

        if (body) {
          headers["Content-Type"] = "application/json";
          serializedBody = JSON.stringify(body);
        }

        if (authorized) {
          headers["Authorization"] = `Bearer ${tokenOverride ?? token}`;
        }

        if (abort) return;
        
        response = await fetch(url, {
          method,
          body: serializedBody,
          headers,
        });

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          data = await response.json();
        } else {
          data = await response.text();
        }
        
        if (abort) return;

        if (!response.ok) {
          if (response.status === 401) {
            console.log("Сессия истекла");
            logout();
          } else throw new Error(data.message || "Что-то пошло не так");
        }

        setNumTry(0);
        setLoading(false);
        clearError();

        if (abort) return;

        return data;
      } catch (e) {
        e.message = e.message || e;
        e.errors = data.errors
        e.status = response.status

        if (response.status === 500 && retryCount < maxRetries) {
          if (abort) return;
          await delay(retryDelay);
          if (abort) return;
          return await request({
            url,
            method,
            body,
            authorized,
            maxRetries,
            retryDelay,
            retryCount: retryCount + 1,
            retryAfterTokenRefresh,
          });
        }

        if (response.status === 400 && data.name === "TokenExpiredError") {
          if (abort) return;
          //console.log('Token expired', token)
          const refreshed = await doRefreshToken();
          if (abort) return;
          //console.log('New token data:', refreshed)
          if (refreshed && refreshed.userId) {
            login(refreshed.jwtToken, refreshed.userId, refreshed.refreshToken);
            // console.log('token', token)
            // console.log('new token', refreshed.jwtToken)
            if (retryAfterTokenRefresh) {
              if (abort) return;
              return await request({
                url,
                method,
                body,
                authorized,
                maxRetries,
                retryDelay,
                retryCount: retryCount + 1,
                retryAfterTokenRefresh,
                tokenOverride: refreshed.jwtToken,
              });
            }
            return;
          }
        }

        if (abort) return;
        setLoading(false);
        setError(e);
        throw e;
      }
    },
    [logout, ready, clearError, request, token, login, doRefreshToken, abort]
  );

  return { loading, request, error, clearError, numTry, cancelRequests, resumeRequests };
};
