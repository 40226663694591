import React, { useCallback, useContext, useEffect, useState } from "react";
import { PageContext } from "../context/PageContext";
import { Alert, Paper } from "@mui/material";
import { useHttp } from "../hooks/http.hook";
import { Loader } from "../components/Loader";
import { SessionList } from "../components/SessionList";
import Title from "../components/Title";

export const ProfilePage = () => {

  const [user, setUser] = useState([]);
  const { loading, request, error } = useHttp();
  const page = useContext(PageContext);

  const fetchUser = useCallback(async () => {
    try {
      const fetched = await request({
        url: "/api/auth/user",
        authorized: true,
        retryAfterTokenRefresh: true
      });
      setUser(fetched);
    } catch (e) {}
  }, [request]); 

  useEffect(() => {
    page.setTitle("Настройки пользователя");
    fetchUser();
  }, [page, fetchUser]);

  if (loading)
    return <Loader />

  if (!loading && error)
    return <Alert security="error">{error.message}</Alert>

  if (user && user.sessions)
  return (
    <>
      <Title>Профиль</Title>
      <p>Email: {user.email}</p>
      <p>Role: {user.role}</p>
      <Title>Список сессий</Title>
      <SessionList sessions={user.sessions} />
    </>
  );

};
