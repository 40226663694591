import { createContext } from "react";

function noop() {}

export const PageContext = createContext({
    title: null,
    eventBus: noop,
    setTitle: noop,
    isMobile: null
})
