import { LockOutlined } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, CircularProgress, Grid, Link, Paper, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { PageContext } from "../context/PageContext";
import { AuthContext } from "../context/AuthContext";
import { useHttp } from "../hooks/http.hook";

export default function AuthPage() {

    const page = useContext(PageContext);
    useEffect(() => {
        page.setTitle("Сервис коротких ссылок");
    }, [page])
    
    const auth = useContext(AuthContext)
    const { loading, error, request, clearError } = useHttp()
    const [ clientError, setClientError ] = useState("")
    const [ authResp, setAuthResp ] = useState(null)
    const [regMode, setRegMode] = useState(false)

    const doAuth = async (data) => {
      try {
            const response = await request({
              url: regMode ? "/api/auth/register" : "/api/auth/login",
              method: "POST",
              body: data,
              maxRetries: 0,
            });
            setAuthResp(response.message);
            //console.log(response);
            setTimeout(() => {
              auth.login(response.token, response.userId, response.refreshToken);
            }, 1000)            
        } catch (e) {
            //console.log(e)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        clearError()
        setClientError("")
        const form = new FormData(event.currentTarget);
        const data = {
            email: form.get('email'),
            password: form.get('password'),
        };
        if (regMode && form.get('password') !== form.get('password2'))
        {
          setClientError("Пароль и подтверждение не совпадают.")
          return;
        }
        await doAuth(data);
    }

  const toggleRegMode = () => {
    setRegMode(rm => !rm)
    clearError()
    setClientError("")
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          p: "30px 40px",
          alignItems: "center",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          {regMode ? "Регистрация нового пользователя" : "Вход в систему"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {regMode && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="password2"
              label="Подтверждение пароля"
              type="password"
              id="password2"
            />
          )}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {(error.errors && error.errors[0]?.msg) ?? error.message}
            </Alert>
          )}
          {clientError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              { clientError }
            </Alert>
          )}
          {authResp && (
            <Alert severity="success" sx={{ mt: 2 }}>
              { authResp + ". Загрузка..."}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            disabled={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2, py: 1 }}
          >
            {regMode ? "Создать аккаунт" : "Войти"}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component="button" type="button" variant="body2">
                Забыли пароль?
              </Link>
            </Grid>
            <Grid item xs>
              {loading && <CircularProgress sx={{ m: "8px 8px 0 20px" }} />}
            </Grid>
            {!regMode && (
              <Grid item>
                <Link
                  component="button"
                  onClick={toggleRegMode}
                  variant="body2"
                >
                  Регистрация
                </Link>
              </Grid>
            )}
            {regMode && (
              <Grid item>
                <Link
                  align="right"
                  component="button"
                  onClick={toggleRegMode}
                  variant="body2"
                >
                  Уже есть аккаунт?
                  <br />
                  Нажмите, чтобы войти
                </Link>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}