import { Alert, AlertTitle, Button, Grid, Link } from "@mui/material"
import { useState } from "react";
import { copyTextToClipboard } from "../helpers";

export const LinkCard = ({link}) => {

    const [btnTitle, setBtnTitle] = useState("Копировать")

    const onCopy = async () => {
        const ok = await copyTextToClipboard(link.shortUrl)
        setTimeout(() => {
            setBtnTitle(ok ? "Успешно скопировано" : "Ошибка! Копируйте сами");
            setTimeout(() => setBtnTitle("Копировать"), 1000);
        }, 300)
    }

    return (
      <Alert severity="success">
        <AlertTitle>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              Ваша короткая ссылка
            </Grid>
            <Grid
              item
              sx={{
                display: { sm: "flex" },
                alignItems: "baseline",
                gap: "30px",
              }}
              spacing={2}
            >
              <Grid item>
                <Link href={link.shortUrl} target="_blank">
                  <b>{link.shortUrl}</b>
                </Link>
              </Grid>
              <Grid item>
                <Button
                  // sx={{ m: { xs: "0px", md: "-6px 0 0" } }}
                  onClick={onCopy}
                  variant="outlined"
                >
                  {btnTitle}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AlertTitle>
        <Grid>
          Исходная ссылка:{" "}
          <Link href={link.target} target="_blank">
            {link.target}
          </Link>
        </Grid>
      </Alert>
    );
}