import { Delete } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useHttp } from "../hooks/http.hook";

export default function DeleteLinkDialog({ link, onRemove }) {
    const [open, setOpen] = useState(false)

    const { loading, request } = useHttp()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = async (del) => {
        setOpen(false)
        if (del) {
            try {
                const resp = await request({
                    url: '/api/link/delete',
                    method: 'POST',
                    body: { linkId: link._id },
                    authorized: true,
                    retryAfterTokenRefresh: true
                })
                if (resp === 'OK')
                {
                    onRemove(link)
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
      <React.Fragment>
        <Box sx={{ m: 1, position: "relative" }}>
          <IconButton disabled={loading}
            aria-label="delete" onClick={handleClickOpen}>
            <Delete />
          </IconButton>
          { loading && <CircularProgress size={32} sx={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-16px',
            ml: '-17px'
           }} /> }
        </Box>
        <Dialog open={open}>
          <DialogTitle>{"Вы действительно хотите удалить ссылку?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Исходная ссылка: {link.target} <br />
              Короткая ссылка: {link.shortUrl}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(true)}>Да</Button>
            <Button onClick={() => handleClose(false)} autoFocus>
              Нет
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
}