import { Typography } from "@mui/material";
//import { Link } from "react-router-dom";

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{mt: '10px'}}>
      {/* {"Copyright © "}
      <Link color="inherit" href="/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."} */}
    </Typography>
  );
}