import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Toolbar } from "@mui/material";
import Copyright from "../components/Copyright";
import AppBar from "../components/AppBar";


export default function UnauthorizedLayout({ children }) {

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        open={false}
        drawerWidth={0}
      />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {children}
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}
