import React, { useContext, useEffect } from "react";
import { PageContext } from "../context/PageContext";
import { LinksControl } from "../components/LinksControl";
import { Box, Paper } from "@mui/material";

export const LinksPage = () => {

  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle("Список ссылок");
  }, [page]);

  return (
    <Box sx={{ p: '10px 20px' }}>
      <LinksControl />
    </Box>
  );

};
